.large-header {
  position: relative;
  width: 100%;
  background: transparent;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.contactbox {
  border: solid rgb(18, 18, 18) 0.5px !important;

  width: 100% !important;
  /* color: rgb(175, 160, 101); */
}
.contacttext {
  text-align: left !important;
  padding-bottom: 1px !important;
  color: black !important;
  font-size: 0.8rem;

  margin-block-end: 0 !important;
  margin-block-start: 0px !important;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
.css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input,
.css-1o6z5ng {
  color: rgb(77, 74, 74) !important;
}

main {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
main > section {
  min-height: 100vh !important;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(251, 227, 132);
}

@media screen and (max-width: 900px) {
  main > section {
    min-height: auto !important;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: auto; */
  }
}

/* 
.react-parallax-bgimage{height: 100% !important;
  width: 100% !important;
} */

.fixediconsize {
  width: 29px !important;
  height: 29px !important;
  object-fit: cover;
}

.slickheight {
  height: 100% !important; /* You may adjust this value */
}

.verticalline {
  border-left: solid 2px !important;
  height: 20px !important;
  margin: auto !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.image-container {
  position: relative;
  width: 100%;
  text-align: end;
  /* height: 100%; */
}

.base-image {
  width: 300px;
  /* height: 100%; */

  z-index: -1;
}

.overlay-image {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 0;
  margin: auto;
  width: 500px;
  height: 300px;
  transform: translate(60px, 20px);
}

.bottom-image {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 0;
  margin: auto;
  width: 600px;
  height: auto;
  transform: translateX(10px);
}

/*****************lessscreen size**********************/
.image-container1 {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
.base-image1 {
  width: 100%;
  height: 100%;

  z-index: -1;
}

.overlay-image1 {
  z-index: 1;
  position: absolute;
  top: 21%;
  left: 0;
  margin: auto;
  width: 74%;
  height: auto;
  transform: translate(18%, 4%);
}

.bottom-image1 {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 0;
  margin: auto;
  width: 90%;
  height: auto;
  transform: translateX(6%);
}

.buttton_eader {
  font-family: "Poppins", sans-serif !important;
  color: white !important;
  font-size: 0.9rem !important;
  text-transform: capitalize !important;
  min-width: 150px;
  background-color: transparent !important;
}

.buttton_eader:active {
  background-color: transparent !important;
}

hr.new2 {
  border-top: 2px dashed lightgray;
}

.responsive-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  height: 100vh !important;
}
