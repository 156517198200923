@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.App {
  text-align: center;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif !important;

}

a {
  color: inherit;
  text-decoration: none;
}
